import React, { useEffect, useRef, useState, useContext } from 'react'
import { AdvancedImage } from '@cloudinary/react';
import { PageContext } from '../../context/PageState';
import cld from '../../constants/cloudinary';
import Slider from "react-slick";

import "./index.scss";

export interface ICustomerStory {
    heading: string;
    stories: {
        id: string;
        customerLogo: {
            src: string;
            alt: string;
        };
        bgImg: {
            src: string;
            alt: string;
        };
        heading: string;
        paragraph: string;
        ctaBtn: {
            text: string;
            link: string;
        };
        stats: {
            headline: string;
            subheadline: string;
        }[];
    }[];
}

const CustomerStories: React.FC<any> = (props: ICustomerStory) => {
    const [customerStorySlide, setCustomerStorySlide] = useState<any>(null);
    const [paginationLogo, setPaginationLogo] = useState<any>(null);

    const { isDesktop, theme } = useContext(PageContext);

    let customerStorySliderRef = useRef(null);
    let paginationLogosRef = useRef(null);

    const paginationLogosSettings = {
        arrows: true,
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        infinite: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: false,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    centerMode: false,
                    swipe: true,
                    arrows: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    swipe: true,
                    arrows: false,
                }
            }
        ]
    };
    const customerStorySettings = {
        autoplay: true,
        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        swipeToSlide: true
    };

    useEffect(() => {
        setCustomerStorySlide(customerStorySliderRef.current);
        setPaginationLogo(paginationLogosRef.current);
    }, [props]);

    return <div className="cp_customer-stories">
        <h2 className="text-center mx-auto">
            {props.heading}
        </h2>
        <div className="container mx-auto">
            <div className="row">
                <div className="col">
                    <Slider
                        className="cp_customer-stories__logos"
                        asNavFor={customerStorySlide}
                        ref={paginationLogosRef}
                        {...paginationLogosSettings}
                    >
                        {props && props.stories.map((story, i) => <div>
                            <AdvancedImage
                                key={i}
                                className="cursor-pointer"
                                cldImg={cld.image(`${story.customerLogo.src}${theme.mode ? "-white" : ""}.svg`)}
                                alt={story.customerLogo.alt}
                            />
                        </div>)}
                    </Slider>
                </div>
            </div>
        </div>
        <Slider
            className="cp_customer-stories__claims"
            asNavFor={paginationLogo}
            ref={customerStorySliderRef}
            {...customerStorySettings}
        >
            {props.stories && props.stories.map((story, i) => <div>
                <div
                    key={i}
                    className="container mx-auto"
                >
                    <div className="row">
                        <div className="col-12 col-lg-6 position-relative d-flex flex-column flex-lg-row">
                        <div className="slide-content flex-grow-1">
                            {story.heading && <h2 className="fw-bold mb-4">{story.heading}</h2>}
                            {story.paragraph && <p style={{ marginBottom: "4rem" }}>{story.paragraph}</p>}
                            <ul>
                                {
                                    story.stats && story.stats.map((stats, j) => <li
                                        key={j}>
                                        <p>
                                            <span className="h2">{stats.headline}</span>
                                            <span>{stats.subheadline}</span>
                                        </p>
                                    </li>)
                                }
                            </ul>
                        </div>
                        <AdvancedImage
                            key={i}
                            className="bgImg flex-shrink-0"
                            cldImg={cld.image(
                                story.bgImg.src +
                                (theme.mode ? "-dark" : "-light") +
                                (isDesktop ? "-desktop" : "-mobile")
                            ).format('webp')}
                            alt={story.customerLogo.alt}
                        />
                    </div>
                </div>
            </div>
        </div>)}
        </Slider>
    </div>;
}

export default CustomerStories;