import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import cld from '../../../../constants/cloudinary';
import { AdvancedImage, lazyload } from '@cloudinary/react';

import "./index.scss";

/**
 * Scene 10
 * Mobile App Developerment
 */

export const scene10Timeline = gsap.timeline();

const Scene10 = () => {

  useGSAP(() => scene10Timeline
    .fromTo([
      ".scene-10__grid-bg",
      ".scene-10__gradient-bg"
    ], {
      autoAlpha: 0
    }, {
      autoAlpha: 1,
    })
    .fromTo(".scene-10__phone-blueprint", {
      autoAlpha: 0,
      transform: "translate(100%, -70%)",
    }, {
      autoAlpha: 1,
      transform: "translate(0, 0)",
      duration: 1
    })
    .fromTo(".scene-10__phone-wireframe", {
      autoAlpha: 0,
      transform: "translate(100%, -100%)",
    }, {
      autoAlpha: 1,
      transform: "translate(0, 0)",
      duration: 1
    }, "<")
    .fromTo(".scene-10__cubes", {
      autoAlpha: 0,
      transform: "translateY(2%)",
    }, {
      autoAlpha: 1,
      transform: "translateY(0)",
      duration: 1,
    }, ">")
    .fromTo([
      ".scene-10__logos .android-logo",
    ], {
      autoAlpha: 0
    }, {
      transform: "translate(-50%, -120%)",
      autoAlpha: 1,
      duration: .75,
      ease: "power2.out"
    })
    .fromTo([
      ".scene-10__logos .apple-logo"
    ], {
      autoAlpha: 0
    }, {
      transform: "translate(-50%, 0%)",
      autoAlpha: 1,
      duration: .75,
      ease: "power2.out"
    }, "<")
    // Cleanup
    .to([
      ".scene-10__grid-bg",
      ".scene-10__gradient-bg",
      ".scene-10__phone-blueprint",
      ".scene-10__phone-wireframe",
      ".scene-10__cubes",
      ".scene-10__logos .android-logo",
      ".scene-10__logos .apple-logo"
    ], {
      autoAlpha: 0,
      duration: 1,
      delay: 1.5
    })
  );

  return <div className="scenes scenes__scene-10">
    <div className="scene-10__gradient-bg"></div>
    <svg className="scene-10__grid-bg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" xmlSpace="preserve">
      <g opacity={.15}>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,83.3c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L132.7-7.2c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.9,83.2C-22,83.3-22.1,83.3-22.2,83.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,90.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L145.5-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,90.1C-21.8,90.1-21.8,90.1-21.9,90.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,97.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l181.2-104c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,97.6C-21.8,97.6-21.8,97.6-21.9,97.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,105.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L172.4-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,105.3C-22.1,105.3-22.1,105.3-22.1,105.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,112.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L185.6-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,112.7C-21.8,112.7-21.8,112.7-21.9,112.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,120.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L197.8-6.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,120.3C-21.8,120.3-21.8,120.3-21.9,120.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,127.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L211.2-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,127.8C-21.8,127.8-21.8,127.8-21.9,127.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.7,135.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L224.4-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.7,135.3C-21.7,135.3-21.7,135.3-21.7,135.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,143.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L237.5-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,143.1C-22.2,143.1-22.2,143.1-22.2,143.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,150.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L250.3-6.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,150.6C-22.1,150.6-22.1,150.6-22.1,150.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,158.8c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1l286-164.2c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1l-286,164.2C-22,158.7-22.1,158.8-22.2,158.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.6,7.5c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L0.4-6.7c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.2,7.4C-21.3,7.4-21.5,7.5-21.6,7.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,14.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L14.7-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.3,14.9C-22.3,14.9-22.3,14.9-22.3,14.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-26.3,2.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l14.5-8.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-26.3,2.2C-26.3,2.2-26.3,2.2-26.3,2.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,22.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L27.8-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.3,22.5C-22.3,22.4-22.3,22.5-22.3,22.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,30c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L39.8-6.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.3,30C-22.3,30-22.3,30-22.3,30z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,37.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L53.2-6.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,37.3C-21.8,37.3-21.8,37.3-21.9,37.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,44.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L66.6-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,44.8C-21.8,44.8-21.8,44.8-21.9,44.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,52.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L79.8-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,52.4C-21.8,52.4-21.8,52.4-21.9,52.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,59.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L92.8-6.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,59.9C-21.8,59.9-21.8,59.9-21.9,59.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,67.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l129-74.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,67.7C-22.2,67.7-22.2,67.7-22.2,67.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,75.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L119.9-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.3,75.3C-22.3,75.3-22.3,75.3-22.3,75.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,165.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l300-172.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.3,165.8C-22.3,165.8-22.3,165.8-22.3,165.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,173.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L290.1-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,173.2C-22,173.2-22,173.2-22.1,173.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,180.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L303.2-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,180.8C-22,180.8-22,180.8-22.1,180.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,188.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L316.4-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,188.2C-21.8,188.2-21.8,188.2-21.9,188.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,195.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L329.5-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,195.9C-22,195.9-22,195.9-22.1,195.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,203.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L342.7-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,203.3C-21.8,203.3-21.8,203.3-21.9,203.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,210.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l378-217c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,210.9C-22,210.9-22,210.9-22.1,210.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.6,218.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L369-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.6,218.2C-21.5,218.2-21.5,218.2-21.6,218.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,226.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L382.1-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,226.1C-22.1,226.1-22.2,226.1-22.2,226.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.3,234.3c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L395.7-7.2c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.9,234.2C-22.1,234.2-22.2,234.3-22.3,234.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,241.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L409.1-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,241.1C-22,241.1-22.1,241.1-22.1,241.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,248.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L421-6.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,248.7C-22,248.7-22,248.7-22.1,248.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,256.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L434.7-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,256.1C-21.8,256.1-21.8,256.1-21.9,256.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,263.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L447.8-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,263.6C-21.8,263.6-21.8,263.6-21.9,263.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,271.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L461-6.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,271.2C-21.8,271.2-21.8,271.2-21.9,271.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,278.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l496-284.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,278.9C-22.1,278.9-22.1,278.9-22.1,278.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,286.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L486.8-6.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,286.5C-22.1,286.5-22.2,286.5-22.2,286.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,293.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L501.1-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,293.9C-21.8,293.9-21.8,293.9-21.9,293.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,301.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l535-307.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,301.4C-21.8,301.4-21.8,301.4-21.9,301.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,309.7c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L527.1-7.2c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.8,309.6C-21.9,309.6-22,309.7-22.1,309.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,316.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L542-7.6c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,316.7C-22.1,316.7-22.2,316.7-22.2,316.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,324.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L555.5-7.8c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,324.1C-21.8,324-21.8,324.1-21.9,324.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,331.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L567.6-7.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,331.8C-22.1,331.8-22.1,331.8-22.1,331.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,339.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L579-6.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,339.3C-22.1,339.3-22.1,339.3-22.1,339.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,346.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L593.1-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,346.9C-22.1,346.9-22.1,346.9-22.2,346.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,354.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L606.3-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,354.4C-22.1,354.4-22.1,354.4-22.1,354.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,361.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L620.2-7.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,361.8C-21.8,361.8-21.8,361.8-21.9,361.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,369.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L632.6-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,369.3C-21.8,369.3-21.8,369.3-21.9,369.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.6,376.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L645.7-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.6,376.7C-21.5,376.7-21.5,376.7-21.6,376.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,385.2c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L659.6-7.7c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.8,385.1C-22,385.1-22.1,385.2-22.2,385.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,392c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L671-6.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,392C-21.8,392-21.8,392-21.9,392z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,399.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L685.3-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,399.7C-22.1,399.7-22.1,399.7-22.1,399.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,407.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L698.5-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,407.2C-22.1,407.2-22.1,407.2-22.1,407.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.1,414.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L710.6-6.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.1,414.8C-22.1,414.8-22.1,414.8-22.1,414.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,422.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L724.8-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,422.4C-22.1,422.4-22.2,422.4-22.2,422.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.7,429.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l759.7-436c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.7,429.6C-21.7,429.6-21.7,429.6-21.7,429.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,437.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l773-443.6c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,437.2C-21.8,437.2-21.8,437.2-21.9,437.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,444.8c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L763-6.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,444.8C-21.8,444.8-21.8,444.8-21.9,444.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,452.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L777.3-6.7c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,452.3C-21.8,452.3-21.8,452.3-21.9,452.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,460.6c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L789-6.5c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L-21.8,460.6C-22,460.6-22.1,460.6-22.2,460.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,467.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L805.5-7.8c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,467.5C-21.8,467.4-21.8,467.5-21.9,467.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.2,475.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L816.6-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.2,475.2C-22.1,475.2-22.2,475.2-22.2,475.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,482.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L828.8-6.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,482.5C-21.8,482.5-21.8,482.5-21.9,482.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.9,490.1c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L844.7-7.8c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.9,490.1C-21.8,490.1-21.8,490.1-21.9,490.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-22.8,498.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l879-504.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-22.8,498.2C-22.8,498.2-22.8,498.2-22.8,498.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.3,504.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L868.2-6.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.3,504.9C-21.3,504.9-21.3,504.9-21.3,504.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-21.6,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l904-519c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-21.6,512.6C-21.5,512.6-21.5,512.6-21.6,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-8,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L895.5-6.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L-8,512.3C-8,512.3-8,512.3-8,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M3.7,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.7-2.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L3.7,513.2C3.7,513.2,3.7,513.2,3.7,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M17.9,513.1c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L902,4c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L18.2,513C18.1,513.1,18,513.1,17.9,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M31.4,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.7,12.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L31.4,512.3C31.5,512.3,31.4,512.3,31.4,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M44.1,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.4,20.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L44.1,512.6C44.2,512.6,44.1,512.6,44.1,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M57.3,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.1,27.8c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L57.3,512.6C57.3,512.6,57.3,512.6,57.3,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M70.8,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L902.2,34.7c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L70.8,512.3C70.9,512.3,70.9,512.3,70.8,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M82.5,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L902,42.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L82.5,513.2C82.6,513.2,82.5,513.2,82.5,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M95.6,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.7,50.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L95.6,513.2C95.7,513.2,95.7,513.2,95.6,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M108.8,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l792.7-455c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L108.8,513.2C108.9,513.2,108.8,513.2,108.8,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M124.6,511.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l778-446.6c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L124.6,511.6C124.7,511.6,124.7,511.6,124.6,511.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M135.1,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L902,72.5c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L135.1,513.2C135.1,513.2,135.1,513.2,135.1,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M149.7,512.9c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L902,79.5c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L150.1,512.8C149.9,512.8,149.8,512.9,149.7,512.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M162.4,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l739-424.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L162.4,512.6C162.5,512.6,162.5,512.6,162.4,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M176,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.7,95.4c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L176,512.3C176.1,512.3,176,512.3,176,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M189.2,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l713.5-409.5c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L189.2,512.3C189.2,512.3,189.2,512.3,189.2,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M201.9,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l699.9-401.7c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L201.9,512.6C201.9,512.6,201.9,512.6,201.9,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M215,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l686.8-394.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L215,512.6C215.1,512.6,215.1,512.6,215,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M228.6,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l672.9-386.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L228.6,512.3C228.7,512.3,228.6,512.3,228.6,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M240.3,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l662.1-380c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L240.3,513.2C240.3,513.2,240.3,513.2,240.3,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M254.4,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l647.1-371.3c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L254.4,512.6C254.4,512.6,254.4,512.6,254.4,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M267.5,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901,148.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L267.5,512.6C267.6,512.6,267.6,512.6,267.5,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M280.7,513.1c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1l621.2-356.5c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L281,513C280.9,513.1,280.8,513.1,280.7,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M292.8,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L902,163.2c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L292.8,513.2C292.8,513.2,292.8,513.2,292.8,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M307.3,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l593.1-340.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L307.3,512.4C307.3,512.4,307.3,512.4,307.3,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M320.4,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l581.9-333.9c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L320.4,512.4C320.5,512.4,320.4,512.4,320.4,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M333.7,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901,186.4c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L333.7,512.3C333.7,512.3,333.7,512.3,333.7,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M346.8,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l555.5-318.8c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L346.8,512.3C346.9,512.3,346.9,512.3,346.8,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M359.7,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l542.1-311.1c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L359.7,512.5C359.7,512.5,359.7,512.5,359.7,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M372.7,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901,209c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L372.7,512.6C372.8,512.6,372.7,512.6,372.7,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M384.8,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l516.6-296.5c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L384.8,513.2C384.9,513.2,384.8,513.2,384.8,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M399,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l502.6-288.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L399,512.6C399.1,512.6,399,512.6,399,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M412,513.2c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1l489.7-281c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1l-489.7,281C412.2,513.2,412.1,513.2,412,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M424.2,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l477.4-274c0.1-0.1,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L424.2,513.2C424.3,513.2,424.3,513.2,424.2,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M437.4,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l464.4-266.5c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L437.4,513.2C437.5,513.2,437.4,513.2,437.4,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M451.7,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2L901.5,254c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L451.7,512.5C451.8,512.5,451.7,512.5,451.7,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M465.2,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l436.3-250.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L465.2,512.3C465.2,512.3,465.2,512.3,465.2,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M477.7,512.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l424.6-243.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L477.7,512.7C477.8,512.6,477.8,512.7,477.7,512.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M490.9,512.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l410.8-235.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L490.9,512.7C490.9,512.6,490.9,512.7,490.9,512.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M504.6,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l397.1-227.9c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L504.6,512.3C504.7,512.3,504.6,512.3,504.6,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M517.6,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l384.2-220.5c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L517.6,512.4C517.7,512.4,517.7,512.4,517.6,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M531.6,511.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l370.1-212.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L531.6,511.9C531.7,511.9,531.6,511.9,531.6,511.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M543.7,513.1c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1L901.8,306c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L544.1,513C544,513,543.8,513.1,543.7,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M556.9,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l344.8-197.9c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L556.9,512.5C556.9,512.5,556.9,512.5,556.9,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M570.2,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l331.4-190.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L570.2,512.4C570.3,512.4,570.3,512.4,570.2,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M584.1,512c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l317.6-182.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L584.1,512C584.1,512,584.1,512,584.1,512z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M596.1,512.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l306-175.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L596.1,512.7C596.1,512.6,596.1,512.7,596.1,512.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M609.6,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l292.8-168c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L609.6,512.5C609.6,512.5,609.6,512.5,609.6,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M622.4,512.7c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l279.3-160.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L622.4,512.7C622.4,512.6,622.4,512.7,622.4,512.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M634,513.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l267.6-153.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L634,513.5C634,513.5,634,513.5,634,513.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M648.7,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l252.8-145c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L648.7,512.6C648.8,512.6,648.7,512.6,648.7,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M662.1,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l239.7-137.6c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L662.1,512.5C662.2,512.5,662.2,512.5,662.1,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M675.5,512.9c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1l226.7-130.1c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L675.9,512.8C675.8,512.8,675.7,512.9,675.5,512.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M688.7,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l212.7-122.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L688.7,512.3C688.7,512.3,688.7,512.3,688.7,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M701.5,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l199.9-114.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L701.5,512.5C701.6,512.5,701.5,512.5,701.5,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M714.6,512.6c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l187-107.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L714.6,512.6C714.6,512.6,714.6,512.6,714.6,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M728.8,511.9c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l173.4-99.5c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L728.8,511.9C728.9,511.9,728.9,511.9,728.8,511.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M741.3,512.3c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l161.1-92.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L741.3,512.3C741.3,512.3,741.3,512.3,741.3,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M754.1,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l147.7-84.7c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L754.1,512.5C754.2,512.5,754.1,512.5,754.1,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M767.3,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l134.1-77c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L767.3,512.5C767.3,512.5,767.3,512.5,767.3,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M780.4,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l121-69.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L780.4,512.5C780.5,512.5,780.5,512.5,780.4,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M793.7,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l108.5-62.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L793.7,512.4C793.8,512.4,793.8,512.4,793.7,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M807.6,512.5c-0.2,0-0.5-0.1-0.6-0.4c-0.2-0.3-0.1-0.8,0.3-1l93.8-53.9c0.3-0.2,0.8-0.1,1,0.3c0.2,0.3,0.1,0.8-0.3,1L808,512.4C807.9,512.5,807.7,512.5,807.6,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M859.5,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l41.9-24.1c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L859.5,512.4C859.6,512.4,859.6,512.4,859.5,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M871.3,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l30.9-17.8c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L871.3,513.2C871.4,513.2,871.4,513.2,871.3,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M885.8,512.4c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l16.4-9.4c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L885.8,512.4C885.9,512.4,885.9,512.4,885.8,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M818.7,513.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l83.5-47.9c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L818.7,513.2C818.8,513.2,818.7,513.2,818.7,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M833,512.5c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l68.5-39.3c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L833,512.5C833,512.5,833,512.5,833,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M847.1,512c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0.1-0.2l54.4-31.2c0.1,0,0.2,0,0.2,0.1c0,0.1,0,0.2-0.1,0.2L847.1,512C847.1,512,847.1,512,847.1,512z" /></g>
      </g>
      <g opacity={.1}>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,128.2c-0.1,0-0.2,0-0.4-0.1L668.6-5.5c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l233,133.7c0.3,0.2,0.5,0.6,0.3,1C902.4,128.1,902.2,128.2,901.9,128.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.2,134.8C901.2,134.8,901.1,134.8,901.2,134.8L655.7-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l245.4,140.8c0.1,0,0.1,0.2,0.1,0.2C901.3,134.8,901.3,134.8,901.2,134.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,142.8C901.9,142.8,901.9,142.8,901.9,142.8L642.6-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,142.4c0.1,0,0.1,0.2,0.1,0.2C902,142.7,902,142.8,901.9,142.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,150.1C901.4,150.1,901.4,150,901.5,150.1L629-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l272.3,156.3c0.1,0,0.1,0.2,0.1,0.2C901.6,150,901.5,150.1,901.5,150.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,157.9C901.9,157.9,901.9,157.8,901.9,157.9L616.6-5.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,157.5c0.1,0,0.1,0.2,0.1,0.2C902,157.8,902,157.9,901.9,157.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.1,165.5C902.1,165.5,902,165.5,902.1,165.5L603.1-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l298.9,171.5c0.1,0,0.1,0.2,0.1,0.2C902.2,165.5,902.1,165.5,902.1,165.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,173C901.9,173,901.9,173,901.9,173L590-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l311.9,179c0.1,0,0.1,0.2,0.1,0.2C902.1,172.9,902,173,901.9,173z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,180.5C901.9,180.5,901.9,180.5,901.9,180.5L576.3-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,180.2c0.1,0,0.1,0.2,0.1,0.2C902.1,180.5,902,180.5,901.9,180.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,187.8C901.4,187.8,901.4,187.8,901.5,187.8L563.3-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l338.1,194c0.1,0,0.1,0.2,0.1,0.2C901.6,187.7,901.5,187.8,901.5,187.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,195.3C901.4,195.3,901.4,195.3,901.5,195.3L550.1-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L901.6,195c0.1,0,0.1,0.2,0.1,0.2C901.6,195.3,901.5,195.3,901.5,195.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,203.7c-0.1,0-0.2,0-0.4-0.1L537.2-5.5c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l364.4,209.1c0.3,0.2,0.5,0.6,0.3,1C902.4,203.6,902.2,203.7,901.9,203.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,52.8c-0.1,0-0.2,0-0.4-0.1L799.7-5.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l101.9,58.5c0.3,0.2,0.5,0.6,0.3,1C902.4,52.7,902.2,52.8,901.9,52.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,59.8C901.9,59.8,901.9,59.8,901.9,59.8l-115.2-66c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l115.1,66c0.1,0,0.1,0.2,0.1,0.2C902.1,59.8,902,59.8,901.9,59.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,67.3C901.9,67.3,901.9,67.3,901.9,67.3L773.6-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,67c0.1,0,0.1,0.2,0.1,0.2C902.1,67.3,902,67.3,901.9,67.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,74.9C901.9,74.9,901.9,74.9,901.9,74.9L760.8-6.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l141,80.9c0.1,0,0.1,0.2,0.1,0.2C902.1,74.8,902,74.9,901.9,74.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,82.4C901.9,82.4,901.9,82.4,901.9,82.4L747.7-6.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,82.1c0.1,0,0.1,0.2,0.1,0.2C902,82.4,902,82.4,901.9,82.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,90C901.9,90,901.9,90,901.9,90l-167.4-96c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l167.3,96c0.1,0,0.1,0.2,0.1,0.2C902.1,89.9,902,90,901.9,90z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.4,97.8C902.4,97.8,902.4,97.8,902.4,97.8L721.1-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l181.3,104c0.1,0,0.1,0.2,0.1,0.2C902.6,97.8,902.5,97.8,902.4,97.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.3,104.7C901.3,104.7,901.3,104.7,901.3,104.7L706.8-6.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l194.5,111.6c0.1,0,0.1,0.2,0.1,0.2C901.5,104.7,901.4,104.7,901.3,104.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,112.6C901.9,112.6,901.9,112.6,901.9,112.6L694.8-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l207,118.8c0.1,0,0.1,0.2,0.1,0.2C902,112.6,902,112.6,901.9,112.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,120.1C901.9,120.1,901.9,120.1,901.9,120.1L681.6-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,119.8c0.1,0,0.1,0.2,0.1,0.2C902.1,120.1,902,120.1,901.9,120.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5-0.9C901.4-0.9,901.4-0.9,901.5-0.9l-9.1-5.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l9,5.2c0.1,0,0.1,0.2,0.1,0.2C901.6-0.9,901.5-0.9,901.5-0.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,6.7C901.4,6.7,901.4,6.7,901.5,6.7L878.9-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l22.5,12.9c0.1,0,0.1,0.2,0.1,0.2C901.6,6.6,901.5,6.7,901.5,6.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,14.2C901.4,14.2,901.4,14.2,901.5,14.2L865.8-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l35.6,20.4c0.1,0,0.1,0.2,0.1,0.2C901.6,14.2,901.5,14.2,901.5,14.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,21.8C901.4,21.8,901.4,21.8,901.5,21.8l-49-28.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l48.9,28.1c0.1,0,0.1,0.2,0.1,0.2C901.6,21.7,901.5,21.8,901.5,21.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,29.3C901.4,29.3,901.4,29.3,901.5,29.3L839.8-6.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L901.6,29c0.1,0,0.1,0.2,0.1,0.2C901.6,29.3,901.5,29.3,901.5,29.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,37.1C901.9,37.1,901.9,37.1,901.9,37.1L826.3-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,36.8c0.1,0,0.1,0.2,0.1,0.2C902.1,37.1,902,37.1,901.9,37.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,44.4C901.4,44.4,901.4,44.4,901.5,44.4l-88-50.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l87.9,50.4c0.1,0,0.1,0.2,0.1,0.2C901.6,44.4,901.5,44.4,901.5,44.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,210.4C901.4,210.4,901.4,210.4,901.5,210.4L523.8-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l377.5,216.6c0.1,0,0.1,0.2,0.1,0.2C901.6,210.4,901.5,210.4,901.5,210.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,218C901.4,218,901.4,218,901.5,218L511.1-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l390.3,224c0.1,0,0.1,0.2,0.1,0.2C901.6,217.9,901.5,218,901.5,218z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,225.8C901.9,225.8,901.9,225.8,901.9,225.8L497.6-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l404.3,232c0.1,0,0.1,0.2,0.1,0.2C902,225.7,902,225.8,901.9,225.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,233.3C901.9,233.3,901.9,233.3,901.9,233.3L483.9-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,233c0.1,0,0.1,0.2,0.1,0.2C902,233.3,902,233.3,901.9,233.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,240.6C901.4,240.6,901.4,240.6,901.5,240.6L471.9-5.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l429.5,246.4c0.1,0,0.1,0.2,0.1,0.2C901.6,240.6,901.5,240.6,901.5,240.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,248.1C901.4,248.1,901.4,248.1,901.5,248.1L458.1-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l443.3,254.4c0.1,0,0.1,0.2,0.1,0.2C901.6,248.1,901.5,248.1,901.5,248.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,255.9C901.9,255.9,901.9,255.9,901.9,255.9L445.3-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,255.6c0.1,0,0.1,0.2,0.1,0.2C902,255.9,902,255.9,901.9,255.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,263.5C901.9,263.5,901.9,263.5,901.9,263.5L432.2-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,263.1c0.1,0,0.1,0.2,0.1,0.2C902,263.5,902,263.5,901.9,263.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,271C901.9,271,901.9,271,901.9,271L418.7-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,270.7c0.1,0,0.1,0.2,0.1,0.2C902,271,902,271,901.9,271z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.2,279.3c-0.1,0-0.2,0-0.4-0.1L404.8-6c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l497.1,285.2c0.3,0.2,0.5,0.6,0.3,1C902.7,279.1,902.4,279.3,902.2,279.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.1,286.2C902.1,286.2,902,286.2,902.1,286.2L391.9-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l510.1,292.7c0.1,0,0.1,0.2,0.1,0.2C902.2,286.2,902.1,286.2,902.1,286.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,293.4C901.4,293.4,901.4,293.4,901.5,293.4L379.1-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l522.3,299.7c0.1,0,0.1,0.2,0.1,0.2C901.6,293.4,901.5,293.4,901.5,293.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,301C901.4,301,901.4,300.9,901.5,301L365.6-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l535.8,307.4c0.1,0,0.1,0.2,0.1,0.2C901.6,300.9,901.5,301,901.5,301z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,308.8C901.9,308.8,901.9,308.7,901.9,308.8L353.3-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,308.4c0.1,0,0.1,0.2,0.1,0.2C902,308.7,902,308.8,901.9,308.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,316C901.4,316,901.4,316,901.5,316L340.1-6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l561.2,322c0.1,0,0.1,0.2,0.1,0.2C901.6,316,901.5,316,901.5,316z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.6,323.7C901.6,323.7,901.6,323.7,901.6,323.7L326.5-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l575.1,330c0.1,0,0.1,0.2,0.1,0.2C901.8,323.6,901.7,323.7,901.6,323.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,331.3C901.8,331.3,901.8,331.3,901.8,331.3L313.3-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L901.9,331c0.1,0,0.1,0.2,0.1,0.2C901.9,331.3,901.9,331.3,901.8,331.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.2,339.1C902.1,339.1,902.1,339.1,902.2,339.1L299.8-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l602.2,345.6c0.1,0,0.1,0.2,0.1,0.2C902.3,339,902.2,339.1,902.2,339.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.6,346.3C901.6,346.3,901.6,346.3,901.6,346.3L286.7-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L901.7,346c0.1,0,0.1,0.2,0.1,0.2C901.8,346.3,901.7,346.3,901.6,346.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,354.5c-0.1,0-0.2,0-0.4-0.1L273.3-6c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l628.2,360.4c0.3,0.2,0.5,0.6,0.3,1C902.3,354.4,902.1,354.5,901.8,354.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.6,361.4C901.6,361.4,901.6,361.4,901.6,361.4L260.4-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l641.1,367.9c0.1,0,0.1,0.2,0.1,0.2C901.8,361.4,901.7,361.4,901.6,361.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,368.9C901.5,368.9,901.4,368.9,901.5,368.9L247.6-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l653.8,375.2c0.1,0,0.1,0.2,0.1,0.2C901.6,368.8,901.6,368.9,901.5,368.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.5,376.4C901.4,376.4,901.4,376.4,901.5,376.4L234.4-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l667,382.7c0.1,0,0.1,0.2,0.1,0.2C901.6,376.4,901.5,376.4,901.5,376.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,384.2C901.8,384.2,901.8,384.2,901.9,384.2L221.4-6.2c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1L902,383.8c0.1,0,0.1,0.2,0.1,0.2C902,384.1,901.9,384.2,901.9,384.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,391.7C901.8,391.7,901.8,391.7,901.8,391.7L208.3-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l693.4,397.9c0.1,0,0.1,0.2,0.1,0.2C901.9,391.7,901.9,391.7,901.8,391.7z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,399.2C901.8,399.2,901.8,399.2,901.8,399.2L195.3-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l706.4,405.4c0.1,0,0.1,0.2,0.1,0.2C901.9,399.1,901.9,399.2,901.8,399.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,406.8C901.8,406.8,901.8,406.7,901.9,406.8L182-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l719.8,413c0.1,0,0.1,0.2,0.1,0.2C902,406.7,901.9,406.8,901.9,406.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.2,414.5C902.1,414.5,902.1,414.5,902.2,414.5L169-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l733.1,420.7c0.1,0,0.1,0.2,0.1,0.2C902.3,414.4,902.2,414.5,902.2,414.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,421.8C901.8,421.8,901.8,421.8,901.8,421.8L155.8-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l745.9,428c0.1,0,0.1,0.2,0.1,0.2C901.9,421.8,901.9,421.8,901.8,421.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,429.9c-0.1,0-0.2,0-0.4-0.1L142.4-5.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l759.1,435.6c0.3,0.2,0.5,0.6,0.3,1C902.4,429.8,902.1,429.9,901.9,429.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,436.9C901.8,436.9,901.8,436.9,901.8,436.9L129.5-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l772.2,443.1c0.1,0,0.1,0.2,0.1,0.2C901.9,436.9,901.9,436.9,901.8,436.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,444.5C901.8,444.5,901.8,444.5,901.9,444.5L116.4-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,444.1c0.1,0,0.1,0.2,0.1,0.2C902,444.4,901.9,444.5,901.9,444.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,452C901.8,452,901.8,452,901.8,452L103.2-6.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l798.5,458.2c0.1,0,0.1,0.2,0.1,0.2C901.9,452,901.9,452,901.8,452z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,459.6C901.8,459.6,901.8,459.6,901.9,459.6L89.6-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l812.2,466c0.1,0,0.1,0.2,0.1,0.2C902,459.5,901.9,459.6,901.9,459.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,467.1C901.8,467.1,901.8,467.1,901.8,467.1L76.5-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l825.3,473.6c0.1,0,0.1,0.2,0.1,0.2C901.9,467,901.9,467.1,901.8,467.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.2,474.8C902.1,474.8,902.1,474.8,902.2,474.8L63.8-6.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l838.3,481c0.1,0,0.1,0.2,0.1,0.2C902.3,474.7,902.2,474.8,902.2,474.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,482.1C901.8,482.1,901.8,482.1,901.8,482.1L50.2-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l851.5,488.6c0.1,0,0.1,0.2,0.1,0.2C901.9,482.1,901.9,482.1,901.8,482.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M902.2,489.9C902.1,489.9,902.1,489.9,902.2,489.9L36.3-6.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l865.7,496.7c0.1,0,0.1,0.2,0.1,0.2C902.3,489.8,902.2,489.9,902.2,489.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,497.2C901.8,497.2,901.8,497.2,901.9,497.2L24-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L902,496.9c0.1,0,0.1,0.2,0.1,0.2C902,497.2,901.9,497.2,901.9,497.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.8,505.3c-0.1,0-0.2,0-0.4-0.1L11.1-5.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l890.4,511c0.3,0.2,0.5,0.6,0.3,1C902.3,505.2,902.1,505.3,901.8,505.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M901.9,512.3C901.8,512.3,901.8,512.3,901.9,512.3L-1.1-5.8c-0.1,0-0.1-0.2-0.1-0.2C-1.2-6.2-1-6.2-1-6.2L902,512c0.1,0,0.1,0.2,0.1,0.2C902,512.3,901.9,512.3,901.9,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M890.1,513.1C890.1,513.1,890,513.1,890.1,513.1L-15.4-6.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l905.4,519.6c0.1,0,0.1,0.2,0.1,0.2C890.2,513.1,890.1,513.1,890.1,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M877.7,513.6C877.7,513.6,877.7,513.6,877.7,513.6L-20.2-1.7c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l897.9,515.3c0.1,0,0.1,0.2,0.1,0.2C877.9,513.5,877.8,513.6,877.7,513.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M863.8,513.1C863.8,513.1,863.7,513.1,863.8,513.1L-21.9,4.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l885.6,508.2c0.1,0,0.1,0.2,0.1,0.2C863.9,513.1,863.9,513.1,863.8,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M850.6,513.1C850.6,513.1,850.6,513.1,850.6,513.1L-23,11.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l873.5,501.3c0.1,0,0.1,0.2,0.1,0.2C850.8,513.1,850.7,513.1,850.6,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M836.9,512.8C836.9,512.8,836.9,512.8,836.9,512.8L-23,19.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L837,512.5c0.1,0,0.1,0.2,0.1,0.2C837.1,512.8,837,512.8,836.9,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M824.3,513.1C824.3,513.1,824.3,513.1,824.3,513.1l-847-486.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l847,486c0.1,0,0.1,0.2,0.1,0.2C824.5,513.1,824.4,513.1,824.3,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M810.6,512.8C810.6,512.8,810.6,512.8,810.6,512.8L-22.7,34.6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l833.3,478.2c0.1,0,0.1,0.2,0.1,0.2C810.8,512.8,810.7,512.8,810.6,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M798,513.1C798,513.1,798,513.1,798,513.1l-820-470.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l819.9,470.5c0.1,0,0.1,0.2,0.1,0.2C798.2,513.1,798.1,513.1,798,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M783.6,513c-0.1,0-0.2,0-0.4-0.1L-22.2,50.7c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3L784,511.7c0.3,0.2,0.5,0.6,0.3,1C784.1,512.9,783.8,513,783.6,513z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M771.5,513.1C771.5,513.1,771.5,513.1,771.5,513.1L-22.7,57.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l794.1,455.8c0.1,0,0.1,0.2,0.1,0.2C771.7,513.1,771.6,513.1,771.5,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M757.9,512.8C757.9,512.8,757.9,512.8,757.9,512.8l-780.6-448c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l780.5,448c0.1,0,0.1,0.2,0.1,0.2C758.1,512.8,758,512.8,757.9,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M744.8,512.8C744.8,512.8,744.7,512.8,744.8,512.8L-22.7,72.4c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l767.4,440.4c0.1,0,0.1,0.2,0.1,0.2C744.9,512.8,744.8,512.8,744.8,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M730.7,512.3C730.7,512.3,730.7,512.3,730.7,512.3L-22.8,79.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L730.8,512c0.1,0,0.1,0.2,0.1,0.2C730.9,512.3,730.8,512.3,730.7,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M718,512.6C718,512.6,718,512.6,718,512.6l-740-424.6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l739.9,424.6c0.1,0,0.1,0.2,0.1,0.2C718.1,512.5,718.1,512.6,718,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M704.7,512.5C704.7,512.5,704.7,512.5,704.7,512.5l-726.7-417c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l726.6,417c0.1,0,0.1,0.2,0.1,0.2C704.8,512.4,704.8,512.5,704.7,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M691.7,512.6C691.7,512.6,691.7,512.6,691.7,512.6L-22.5,102.7c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l714.2,409.9c0.1,0,0.1,0.2,0.1,0.2C691.9,512.5,691.8,512.6,691.7,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M678.4,512.5C678.4,512.5,678.4,512.5,678.4,512.5l-701-402.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l700.9,402.2c0.1,0,0.1,0.2,0.1,0.2C678.5,512.4,678.5,512.5,678.4,512.5z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M665.1,512.4C665.1,512.4,665,512.4,665.1,512.4L-22.7,117.7c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1L665.2,512c0.1,0,0.1,0.2,0.1,0.2C665.2,512.3,665.2,512.4,665.1,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M652.4,513.2c-0.1,0-0.2,0-0.4-0.1L-22.8,125.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l674.9,387.3c0.3,0.2,0.5,0.6,0.3,1C652.9,513.1,652.7,513.2,652.4,513.2z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M638.7,512.3C638.7,512.3,638.7,512.3,638.7,512.3L-22.5,132.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L638.8,512c0.1,0,0.1,0.2,0.1,0.2C638.8,512.3,638.8,512.3,638.7,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M626,512.6C626,512.6,625.9,512.6,626,512.6L-22.5,140.4c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l648.4,372.1c0.1,0,0.1,0.2,0.1,0.2C626.1,512.5,626.1,512.6,626,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M613.8,513.1C613.7,513.1,613.7,513.1,613.8,513.1L-22.5,147.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l636.2,365.1c0.1,0,0.1,0.2,0.1,0.2C613.9,513.1,613.8,513.1,613.8,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M600.2,512.8C600.1,512.8,600.1,512.8,600.2,512.8l-622.1-357c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l622,357c0.1,0,0.1,0.2,0.1,0.2C600.3,512.8,600.2,512.8,600.2,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M586.6,512.6C586.5,512.6,586.5,512.6,586.6,512.6L-22.5,163c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l609,349.5c0.1,0,0.1,0.2,0.1,0.2C586.7,512.5,586.6,512.6,586.6,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M573,512.3C573,512.3,573,512.3,573,512.3l-595-341.4c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L573.1,512c0.1,0,0.1,0.2,0.1,0.2C573.1,512.3,573.1,512.3,573,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M560.4,512.6C560.4,512.6,560.3,512.6,560.4,512.6L-22.5,178.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l582.8,334.5c0.1,0,0.1,0.2,0.1,0.2C560.5,512.6,560.5,512.6,560.4,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M546.7,512.3C546.7,512.3,546.6,512.3,546.7,512.3L-22.5,185.7c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1L546.8,512c0.1,0,0.1,0.2,0.1,0.2C546.8,512.3,546.7,512.3,546.7,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M534.4,512.8C534.4,512.8,534.3,512.8,534.4,512.8L-22.5,193.3c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1l556.8,319.5c0.1,0,0.1,0.2,0.1,0.2C534.5,512.8,534.5,512.8,534.4,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M520.4,512.9c-0.1,0-0.2,0-0.4-0.1L-22.8,201.3c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l542.9,311.5c0.3,0.2,0.5,0.6,0.3,1C520.9,512.8,520.7,512.9,520.4,512.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M507.8,512.6C507.7,512.6,507.7,512.6,507.8,512.6L-21.9,208.7c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l529.6,303.9c0.1,0,0.1,0.2,0.1,0.2C507.9,512.6,507.8,512.6,507.8,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M494.1,512.4C494.1,512.4,494.1,512.4,494.1,512.4L-21.9,216.3c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l516,296.1c0.1,0,0.1,0.2,0.1,0.2C494.3,512.3,494.2,512.4,494.1,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M481,512.4C481,512.4,480.9,512.4,481,512.4L-22.5,223.5c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1L481.1,512c0.1,0,0.1,0.2,0.1,0.2C481.1,512.3,481.1,512.4,481,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M468.2,512.6C468.2,512.6,468.1,512.6,468.2,512.6L-22.5,231c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l490.6,281.5c0.1,0,0.1,0.2,0.1,0.2C468.3,512.5,468.2,512.6,468.2,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M455,512.6C455,512.6,455,512.6,455,512.6l-477-273.7c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l476.9,273.6c0.1,0,0.1,0.2,0.1,0.2C455.2,512.5,455.1,512.6,455,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M441.9,512.6C441.9,512.6,441.8,512.6,441.9,512.6L-22.5,246.1c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1L442,512.2c0.1,0,0.1,0.2,0.1,0.2C442,512.5,442,512.6,441.9,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M428.4,512.4C428.4,512.4,428.3,512.4,428.4,512.4L-21.9,254c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L428.5,512c0.1,0,0.1,0.2,0.1,0.2C428.5,512.3,428.5,512.4,428.4,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M415.6,512.6C415.6,512.6,415.5,512.6,415.6,512.6l-437.5-251c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l437.4,251c0.1,0,0.1,0.2,0.1,0.2C415.7,512.5,415.7,512.6,415.6,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M402.8,512.8C402.8,512.8,402.8,512.8,402.8,512.8l-425.4-244c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1l425.3,244c0.1,0,0.1,0.2,0.1,0.2C403,512.8,402.9,512.8,402.8,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M389.7,513.3c-0.1,0-0.2,0-0.4-0.1L-22.8,276.8c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3L390,512c0.3,0.2,0.5,0.6,0.3,1C390.2,513.2,389.9,513.3,389.7,513.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M376.1,512.6C376.1,512.6,376.1,512.6,376.1,512.6L-22.5,283.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l398.6,228.7c0.1,0,0.1,0.2,0.1,0.2C376.3,512.5,376.2,512.6,376.1,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M363,512.6C363,512.6,362.9,512.6,363,512.6L-22,291.7c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l384.9,220.9c0.1,0,0.1,0.2,0.1,0.2C363.1,512.5,363.1,512.6,363,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M350,512.6C350,512.6,349.9,512.6,350,512.6L-22.5,298.9c-0.1,0-0.1-0.2-0.1-0.2c0.1-0.1,0.2-0.1,0.2-0.1l372.4,213.7c0.1,0,0.1,0.2,0.1,0.2C350.1,512.6,350,512.6,350,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M337.6,513.1C337.6,513.1,337.6,513.1,337.6,513.1L-22,306.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l359.5,206.3c0.1,0,0.1,0.2,0.1,0.2C337.7,513.1,337.7,513.1,337.6,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M323.9,512.8C323.9,512.8,323.9,512.8,323.9,512.8L-21.9,314.4c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L324,512.5c0.1,0,0.1,0.2,0.1,0.2C324.1,512.8,324,512.8,323.9,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M310.4,512.6C310.4,512.6,310.3,512.6,310.4,512.6L-21.9,321.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l332.3,190.6c0.1,0,0.1,0.2,0.1,0.2C310.5,512.5,310.5,512.6,310.4,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M297.6,512.8C297.6,512.8,297.6,512.8,297.6,512.8L-21.9,329.4c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l319.5,183.3c0.1,0,0.1,0.2,0.1,0.2C297.8,512.8,297.7,512.8,297.6,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M283.8,512.4C283.7,512.4,283.7,512.4,283.8,512.4L-22,337c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L283.9,512c0.1,0,0.1,0.2,0.1,0.2C283.9,512.3,283.8,512.4,283.8,512.4z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M271,512.6C270.9,512.6,270.9,512.6,271,512.6L-22.5,344.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L271,512.2c0.1,0,0.1,0.2,0.1,0.2C271.1,512.5,271,512.6,271,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M257.4,512.9c-0.1,0-0.2,0-0.4-0.1L-22.1,352.6c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l279.1,160.2c0.3,0.2,0.5,0.6,0.3,1C257.9,512.7,257.6,512.9,257.4,512.9z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M204.8,512.3C204.8,512.3,204.7,512.3,204.8,512.3L-22,382.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L204.9,512c0.1,0,0.1,0.2,0.1,0.2C204.9,512.3,204.9,512.3,204.8,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M192.1,512.6C192,512.6,192,512.6,192.1,512.6L-22,389.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l214,122.8c0.1,0,0.1,0.2,0.1,0.2C192.2,512.5,192.1,512.6,192.1,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M178.5,512.3C178.5,512.3,178.4,512.3,178.5,512.3l-200.4-115c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l200.3,115c0.1,0,0.1,0.2,0.1,0.2C178.6,512.3,178.5,512.3,178.5,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M165.8,512.6C165.7,512.6,165.7,512.6,165.8,512.6l-188.2-108c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l188.2,108c0.1,0,0.1,0.2,0.1,0.2C165.9,512.5,165.8,512.6,165.8,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M153,512.8C153,512.8,152.9,512.8,153,512.8L-22.4,412.2c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l175.3,100.6c0.1,0,0.1,0.2,0.1,0.2C153.1,512.8,153.1,512.8,153,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M139.5,512.6C139.4,512.6,139.4,512.6,139.5,512.6l-161.9-92.8c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l161.8,92.8c0.1,0,0.1,0.2,0.1,0.2C139.6,512.5,139.5,512.6,139.5,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M126.3,513.1c-0.1,0-0.2,0-0.4-0.1l-148-84.9c-0.3-0.2-0.5-0.6-0.3-1c0.2-0.3,0.6-0.5,1-0.3l148,84.9c0.3,0.2,0.5,0.6,0.3,1C126.8,513,126.6,513.1,126.3,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M112.8,512.3C112.7,512.3,112.7,512.3,112.8,512.3l-135.2-77.5c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L112.8,512c0.1,0,0.1,0.2,0.1,0.2C112.9,512.3,112.8,512.3,112.8,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M100.4,512.8C100.4,512.8,100.3,512.8,100.4,512.8L-22,442.6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l122.3,70.2c0.1,0,0.1,0.2,0.1,0.2C100.5,512.8,100.5,512.8,100.4,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M87.3,512.8C87.2,512.8,87.2,512.8,87.3,512.8L-22,450.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l109.2,62.6c0.1,0,0.1,0.2,0.1,0.2C87.4,512.8,87.3,512.8,87.3,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M73.3,512.3C73.3,512.3,73.2,512.3,73.3,512.3l-95.7-54.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L73.4,512c0.1,0,0.1,0.2,0.1,0.2C73.4,512.3,73.4,512.3,73.3,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M60.2,512.3C60.1,512.3,60.1,512.3,60.2,512.3l-82.1-47.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l82,47.1c0.1,0,0.1,0.2,0.1,0.2C60.3,512.3,60.2,512.3,60.2,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M47,512.3C47,512.3,46.9,512.3,47,512.3l-69-39.6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L47.1,512c0.1,0,0.1,0.2,0.1,0.2C47.1,512.3,47.1,512.3,47,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M35.2,513.1C35.2,513.1,35.2,513.1,35.2,513.1l-57.5-33c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l57.5,33c0.1,0,0.1,0.2,0.1,0.2C35.3,513.1,35.3,513.1,35.2,513.1z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M21.5,512.8C21.5,512.8,21.5,512.8,21.5,512.8l-43.5-24.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l43.4,24.9c0.1,0,0.1,0.2,0.1,0.2C21.6,512.8,21.6,512.8,21.5,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M7.6,512.3C7.5,512.3,7.5,512.3,7.6,512.3l-29.7-17c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l29.6,17c0.1,0,0.1,0.2,0.1,0.2C7.7,512.3,7.6,512.3,7.6,512.3z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-5.6,512.6c-0.1,0-0.2,0-0.2-0.1l-16.4-9.4c-0.2-0.1-0.3-0.4-0.2-0.7c0.1-0.2,0.4-0.3,0.7-0.2l16.4,9.4c0.2,0.1,0.3,0.4,0.2,0.7C-5.3,512.5-5.4,512.6-5.6,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M-17.9,512.8C-18,512.8-18,512.8-17.9,512.8l-3.8-2.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l3.7,2.1c0.1,0,0.1,0.2,0.1,0.2C-17.8,512.8-17.9,512.8-17.9,512.8z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M244.7,512.6C244.6,512.6,244.6,512.6,244.7,512.6L-22,359.6c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l266.6,153c0.1,0,0.1,0.2,0.1,0.2C244.8,512.5,244.7,512.6,244.7,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M231.5,512.6C231.5,512.6,231.4,512.6,231.5,512.6L-22,367.1c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1l253.4,145.4c0.1,0,0.1,0.2,0.1,0.2C231.6,512.5,231.6,512.6,231.5,512.6z" /></g>
        <g><path style={{fill: "#FCFCFC"}} d="M217.9,512.3C217.9,512.3,217.9,512.3,217.9,512.3L-21.6,374.9c-0.1,0-0.1-0.2-0.1-0.2c0-0.1,0.2-0.1,0.2-0.1L218,512c0.1,0,0.1,0.2,0.1,0.2C218.1,512.3,218,512.3,217.9,512.3z" /></g>
      </g>
    </svg>
    <AdvancedImage
      className="scene-10__cubes"
      cldImg={cld.image("components/we-are-animation/mobile-app-cubes").format('webp')}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="multiple cubes with squares hovering over depicting mobile apps"
    />
    <AdvancedImage
      className="scene-10__phone-blueprint"
      cldImg={cld.image("components/we-are-animation/phone-blueprint")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="a mobile phone with sqaure depicting apps"
    />
    <AdvancedImage
      className="scene-10__phone-wireframe"
      cldImg={cld.image("components/we-are-animation/phone-wireframe")}
      plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
      alt="bluprint of a devlop mobile application"
    />

    <div className="scene-10__logos">
      <AdvancedImage
        className="android-logo"
        cldImg={cld.image("value-proposition/2020/android-white")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Android logo"
      />
      <AdvancedImage
        className="apple-logo"
        cldImg={cld.image("value-proposition/2020/ios-white.svg")}
        plugins={[lazyload({rootMargin: '10px 20px 10px 30px', threshold: 0.25})]}
        alt="Apple logo"
      />
    </div>

  </div>

}

export default Scene10;