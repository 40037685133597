import { ICustomerStory } from '../../components/CustomerStories';
import { IJumpPadLink } from '../../components/JumpPad';
import { ITestimonialSliderTestimonial } from '../../components/TestimonialSlider';

export const hardwareLinksData: IJumpPadLink[] = [
  {
    title: "iOS Tech",
    className: "ios-tech",
    url: "/products/ios-tech",
    image: {
      src: "home/v2/hardware/apple-tech",
      alt: "linea pro rugged device with an iPhone device"
    },
    hidden: false
  },
  {
    title: "Android Tech",
    className: "android-tech",
    url: "/products/android-tech",
    image: {
      src: "home/v2/hardware/android-tech",
      alt: "a Linea Pro Rugged for Google with a Google Pixel"
    },
    hidden: false
  },
  {
    title: "Payment Tech",
    url: "/products/payment-tech",
    image: {
      src: "home/v2/hardware/payment-tech",
      alt: "A linea Pro with a QuantumPay Reader showing different ways to take payments"
    },
    hidden: false
  },
  {
    title: "Wearables",
    url: "/products/wearable-tech",
    image: {
      src: "home/v2/hardware/haloring-wearables",
      alt: "a HaloRing device"
    },
    hidden: false
  },
  {
    title: "Self-Service Kiosks",
    url: "/products/self-service-kiosks",
    image: {
      src: "home/v2/hardware/kiosks",
      alt: "a kiosk device"
    },
    hidden: false
  },
  {
    title: "Mobile Printers",
    url: "/products/mobile-printers",
    image: {
      src: "home/v2/hardware/printer",
      alt: "a printer"
    },
    hidden: false
  },
  {
    title: "Beacons",
    url: "/products/beacons",
    image: {
      src: "home/v2/hardware/beacon",
      alt: "beacon tracking device"
    },
    hidden: false
  },
  {
    title: "Enterprise Tablets",
    url: "/products/enterprise-tablets",
    image: {
      src: "home/v2/hardware/enterprise-tablets",
      alt: "a tablet device"
    },
    hidden: false
  },
  {
    title: "All-in-One Android Payment",
    url: "/products/all-in-one-android-payments",
    image: {
      src: "home/v2/hardware/all-in-one-device",
      alt: "an all-in-one android payment device"
    },
    hidden: false
  },
  {
    title: "View All Hardware",
    url: "/devices",
    image: {
      src: "icons/view-more",
      alt: "a plus sign"
    },
    hidden: false
  }
]

export const softwareLinksData: IJumpPadLink[] = [
  {
    title: "Mobile Development",
    url: "/software/mobile-development",
    image: {
      src: "home/v2/software/mobile-development",
      alt: "mobile device with several a play and code icon on top"
    },
    hidden: false
  },
  {
    title: "Cloud Development",
    url: "/software/cloud-development",
    image: {
      src: "home/v2/software/cloud-development",
      alt: "cloud showing code brackets"
    },
    hidden: false
  },
  {
    title: "QuantumPay",
    url: "/software/quantumpay",
    image: {
      src: "home/v2/software/quantumpay",
      alt: "QuantumPay logo icon"
    },
    hidden: false
  },
  {
    title: "Extended Development Team",
    url: "/software/extended-development-team",
    image: {
      src: "home/v2/software/extended-team",
      alt: "shape of three peopl outlined"
    },
    hidden: true
  },
  {
    title: "Rapid Deployment Kits",
    url: "/software/rapid-deployment-kits",
    image: {
      src: "home/v2/software/rapid-deployment-kits",
      alt: "Letters R, D, and K inside a square with rounded corners"
    },
    hidden: false
  },
  {
    title: "Quality Assurance, Testing, and Software Support",
    url: "/software/quality-assurance-testing-support",
    image: {
      src: "home/v2/software/quality-assurance",
      alt: "A ribbon with a checkmark in the center"
    },
    hidden: true
  },
  {
    title: "Bespoke Software Development ",
    url: "/software/enterprise-bespoke-software-development",
    image: {
      src: "home/v2/software/bespoke-software-development",
      alt: "A pencil inside two dotted sqaures depicting a blueprint"
    },
    hidden: false
  },
  {
    title: "View All Software",
    url: "/",
    image: {
      src: "icons/view-more",
      alt: "a plus sign"
    },
    hidden: false
  }
]

export const customerStoriesData: ICustomerStory = {
  heading: 'Trusted by over 2,000 customers worldwide',
  stories: [
    {
      id: 'disney',
      customerLogo: {
        src: 'customer-logos/walt-disney-company',
        alt: 'Walt Disney Company Logo',
      },
      bgImg: {
        src: 'customer-stories/walt-disney-company-customer-story',
        alt: 'Fireworks over Walt Disney World Castle'
      },
      heading: 'Creating Magic with Mobility',
      paragraph: 'IPCMobile technologies power the world’s most iconic theme parks, cruise lines, and resort hotels - providing seamless and innovative experiences for admissions, supply chain management, retail, parking, and express experiences like Disney Genie+ and MagicBand.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '12 Theme Parks',
          subheadline: 'Approx. 100M Visitors per Year',
        },
        {
          headline: '6 Cruise Lines',
          subheadline: '94 Ports in 40 Countries',
        },
        {
          headline: '18 Resort Hotels',
          subheadline: 'Around the World',
        }
      ]
    },
    {
      id: 'amtrak',
      customerLogo: {
        src: 'customer-logos/amtrak-logo',
        alt: 'AMTRAK Logo',
      },
      bgImg: {
        src: 'customer-stories/amtrak-customer-story',
        alt: 'AMTRAK Ticket Scanning'
      },
      heading: 'Smoother Rides and Operations',
      paragraph: 'The top passenger rail transportation company in the US trusts IPCMobile as their go-to mobility partner - providing unparalleled support and custom solutions for seamless operations, ticket validation, and improving rider experiences.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '28.6M Riders Validated',
          subheadline: 'Nationwide in 2023',
        },
        {
          headline: '78,300 Daily Trips on Average',
          subheadline: 'with 500 Destinations Across the US and Canada',
        },
        {
          headline: '300+ Trains Operating Daily',
          subheadline: 'Covering 21,4000+ Miles of Routes',
        }
      ]
    },
    {
      id: 'fedex',
      customerLogo: {
        src: 'customer-logos/fedex-logo',
        alt: 'FedEx Logo',
      },
      bgImg: {
        src: "customer-stories/fedex-customer-story",
        alt: 'FedEx Ship and Go Kiosk'
      },
      heading: 'Delivering Express Experiences',
      paragraph: 'Strengthening supply chains and improving customer satisfaction with one of the top, global shipping couriers by deploying self-service kiosks that decrease line queues and facilitate express experiences.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '32K+ Drop Box Locations',
          subheadline: 'with Self-Service Kiosks',
        },
        {
          headline: '220+ Countries and Territories',
          subheadline: 'with Air-Ground Services',
        },
        {
          headline: '16.5M Packages Delivered',
          subheadline: 'Per Day on Average',
        }
      ]
    },
    {
      id: 'ucla-health',
      customerLogo: {
        src: 'customer-logos/ucla-health-logo',
        alt: 'UCLA Health Logo',
      },
      bgImg: {
        src: 'customer-stories/ucla-customer-story',
        alt: 'Health Professionals in a Hospital Setting'
      },
      heading: 'Reliable Care Everywhere',
      paragraph: 'IPCMobile supports one of the most advanced health care systems in the world by enabling healthcare professionals with hospital-grade mobile devices for state-of-the-art, around-the-clock patient care.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '280 Community Clinics',
          subheadline: 'Supporting 3.6M Outpatient Visits Annually',
        },
        {
          headline: '5 Medical Hospitals',
          subheadline: 'with 800+ Patient Beds',
        },
        {
          headline: 'Ranked #1 in California',
          subheadline: 'in 2024 by U.S. News',
        }
      ]
    },
    {
      id: 'tmobile',
      customerLogo: {
        src: 'customer-logos/t-mobile-logo',
        alt: 'T-Mobile Logo',
      },
      bgImg: {
        src: 'customer-stories/tmobile-customer-story',
        alt: 'Health Professionals in a Hospital Setting'
      },
      heading: 'Strengthening Customer Connections',
      paragraph: 'Enhancing retail operations and customer experiences for the world’s largest telecommunications company in 2024 - deploying best-in-class, data capture and inventory management solutions with seamless integration support.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '6,265 Retail Locations',
          subheadline: 'Across the U.S.',
        },
        {
          headline: '119M+ Total Customers',
          subheadline: 'Supported in 2023',
        },
        {
          headline: '10+ Years with IPCMobile',
          subheadline: 'Mobility Expertise and Integration Support',
        }
      ]
    },
    {
      id: 'toyota',
      customerLogo: {
        src: 'customer-logos/toyota-logo-2',
        alt: ' Logo',
      },
      bgImg: {
        src: 'customer-stories/toyota-customer-story',
        alt: 'phone in a lot locating a trailer with beacon technology'
      },
      heading: 'Higher Profits with Asset Tracking',
      paragraph: 'The world’s top-selling automaker is boosting business operations by partnering with IPCMobile to deploy mobility solutions that increase efficiency and reduce the costs associated with asset tracking and fleet management.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '13 Manufacturing Plants',
          subheadline: 'Across the U.S.',
        },
        {
          headline: '24M+ Sq. Ft. of Facilities',
          subheadline: 'for Mass Production',
        },
        {
          headline: '11.2M Vehicles Shipped in 2023',
          subheadline: 'to 1,800+ Dealerships',
        }
      ]
    },
    {
      id: 'space-x',
      customerLogo: {
        src: 'customer-logos/space-x-logo',
        alt: 'SpaceX Logo',
      },
      bgImg: {
        src: 'customer-stories/space-x-customer-story',
        alt: 'Space X rocket and two people walking in front of rocket'
      },
      heading: 'Breaking Boundaries in Space',
      paragraph: 'Making history in the aerospace industry with the first private spacecraft manufacturer to produce reusable rockets and spacecrafts for commercial use - leveraging IPCMobile’s rugged mobility solutions for development, manufacturing, testing, and launch missions.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '6 Facilities Across the U.S.',
          subheadline: 'with *3M+ Sq. Ft. of Production Space',
        },
        {
          headline: '384 Launches and Counting',
          subheadline: 'Including 348 Landings and 317 Reflights',
        },
        {
          headline: '1st Civilian Spacewalk ',
          subheadline: 'by a Private Aerospace Company',
        }
      ]
    },
    {
      id: 'carnival',
      customerLogo: {
        src: 'customer-logos/carnival-logo',
        alt: 'Carnival Logo',
      },
      bgImg: {
        src: 'customer-stories/carnival-customer-story',
        alt: 'carnival ships in the bahamas'
      },
      heading: 'Carefree Onboard Experiences',
      paragraph: 'IPCMobile empowers the world’s largest cruise line by transforming their tablets into mobile workstations - increasing revenue with cashless workflows for offline transactions and creating more efficient, frictionless experiences for both guests and crew.',
      ctaBtn: {
        text: 'Learn More',
        link: 'URL'
      },
      stats: [
        {
          headline: '87 Total Ships ',
          subheadline: '',
        },
        {
          headline: '1,500+ Voyages Annually',
          subheadline: 'with 700+ Destinations Around the World',
        },
        {
          headline: '13M+ Passengers Per Year',
          subheadline: '',
        }
      ]
    }
  ]
}

export const customerTestimonialsData: ITestimonialSliderTestimonial[] = [
  {
    backgroundImage: "home/v2/customer-testimonials/kpt/background",
    className: "kpt",
    entityName: "King Power Traveler Co. Ltd.",
    entityLogoSrc: "home/v2/customer-testimonials/kpt/logo",
    representative: {
      fullName: "Rakhita Jayawardena",
      title: "President",
      quote: "We have been partnering with IPC for our POS software services since 2014, and the efficiency and reliability of IPC's software solutions have significantly streamlined our processes, making it seamless for us to manage sales reporting and inventory. The real-time data insights provided by their software have empowered us to make informed decisions, contributing to the overall success of our inflight duty-free services."
    }
  },
  {
    backgroundImage: "home/v2/customer-testimonials/oai/background",
    className: "oai",
    entityName: "Omni Air International",
    entityLogoSrc: "home/v2/customer-testimonials/oai/logo",
    representative: {
      fullName: "Art Seabolt",
      title: "Vice President of Technology",
      quote: "Working with IPCMobile allowed us to ensure the team was able to deliver the critical components we needed for our airline. The in-flight team was able to adopt a modern approach which has improved our operational effectiveness and customer satisfaction while in the skies."
    }
  },
  {
    backgroundImage: "home/v2/customer-testimonials/ocfa/background",
    className: "ocfa",
    entityName: "Orange County Fire Association",
    entityLogoSrc: "home/v2/customer-testimonials/ocfa/logo",
    representative: {
      fullName: "Matt Job",
      title: "Service Center Specialist",
      quote: "[IPCMobile] let us borrow a bunch of their equipment to show everybody that this is something that could work for us. What started as just an inventory scanning solution became asset tracking, inventory, shipping, and receiving."
    }
  }
]